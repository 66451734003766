jQuery(document).ready(function($) {

	// Quantity field type increase / decrease
	//to increase of decrease count for adults and children fields
	$(".quantity-button").on("click", function() {
		var button = $(this);
		var quantityInputContainer = button.parent().parent().find("input");
		var oldValue = quantityInputContainer.val();

		if (button.hasClass('quantity-up')) {
			var newVal = parseFloat(oldValue) + 1;
		} else {
			// Don't allow decrementing below zero
			if (oldValue > 0) {
				var newVal = parseFloat(oldValue) - 1;
			} else {
		      newVal = 0;
			}
		}

		quantityInputContainer.val(newVal);

	});

	// Handle the booking button click.
	$('.booking-btn.booking-widget-type-toggle-bar, .booking-btn.booking-widget-type-toggle-widget').on('click', function(event) {
		$('.header-booking-wrapper').slideToggle();
		event.preventDefault();
	});

	$('.close-booking-widget').on('click', function(event) {
		event.preventDefault();
		$('.header-booking-wrapper').slideToggle();
	});

	var $picker_checkout = $('.booking-widget-calendar:eq(1) input').pickadate({
		min: true,
		format: 'd mmm',
		formatSubmit: 'yyyy/mm/dd',
		hiddenName: true,
		klass: {
			// Month navigation
			navPrev: 'picker__nav--prev bg-c2_hover border-right-c1--before',
			navNext: 'picker__nav--next bg-c2_hover border-left-c1--before',
			navDisabled: 'picker__nav--disabled bg-c2_hover',

			// Month & year labels
			month: 'picker__month fg-c1',
			year: 'picker__year fg-c1',

			highlighted: 'picker__day--highlighted bg-c2-opacity border-c1 border-top-c2--before',
			now: 'picker__day--today bg-c2-opacity',
			infocus: 'picker__day--infocus fg-c1 bg-c2_hover',
			outfocus: 'picker__day--outfocus fg-c2 bg-c2_hover',

			// Today, clear, & close buttons
			buttonClear: 'picker__button--clear fg-c1 border-top-c1--before',
			buttonClose: 'picker__button--close fg-c1',
			buttonToday: 'picker__button--today fg-c1 border-top-c2--before'
		},
		onOpen: function(context) {
			$('.header-slider .flexslider .flex-control-nav').hide();
			$('.header-slider .flexslider .flex-direction-nav').hide();
			console.log('datePicker checkout onOpen fired.');
			$('.picker__weekday-display').addClass('row-calendar-checkout');
			$('.picker__weekday-display').removeClass('row-calendar-checkin');
		},
		onSet: function(context) {
			console.log('Checkout onSet fired.');
			$checkoutDate = $('.booking-widget-calendar:eq(1) input').pickadate('picker').get();
			$checkinDate = $('.booking-widget-calendar:eq(0) input').pickadate('picker').get();
			if (context.select) {
				// a date has been selected
				// console.log('Checkout onSet fired, context.select=true.');
				// Close the checkout calendar.
				$picker_checkout.pickadate('picker').close();
				// Show the date in the input field now that one's been set.
					$('.booking-widget-calendar:eq(1) input').css('opacity', '1');
				// Hide the placeholder "checkout" label on the input field.
				$('.row-reservation-bar-datepicker.checkout label').css('opacity', '0');
				// Set the max date on the checkin calendar.
				$maxDate = new Date(context.select);
				// console.log('max date set to ' + $maxDate);
				$('.booking-widget-calendar:eq(0) input').pickadate('picker').set('max',$maxDate);
			} else {
				// The user did not pick a date, they clicked outside the calendar or on the close button.
				// console.log('Checkout onSet fired, context.select=false.');
				// Make sure the label on the calendar stays on "checkout"
				$('.picker__weekday-display').addClass('row-calendar-checkout');
				$('.picker__weekday-display').removeClass('row-calendar-checkin');
				if ( $checkoutDate == '') {
					// Show the date they picked in the input field.
					$('.booking-widget-calendar:eq(1) input').css('opacity', '0');
					// Hide the "checkin" label now that a date is set.
					$('.row-reservation-bar-datepicker.checkout label').css('opacity', '1');
				}
			}
		},
		onClose: function(context) {
			$('.header-slider .flexslider .flex-control-nav').show();
			$('.header-slider .flexslider .flex-direction-nav').show();
		}
	});
	var $picker_checkin = $('.booking-widget-calendar:eq(0) input').pickadate({
		min: true,
		format: 'd mmm',
		formatSubmit: 'yyyy/mm/dd',
		hiddenName: true,
		klass: {
			// Month navigation
			navPrev: 'picker__nav--prev bg-c2_hover border-right-c1--before',
			navNext: 'picker__nav--next bg-c2_hover border-left-c1--before',
			navDisabled: 'picker__nav--disabled bg-c2_hover',

			// Month & year labels
			month: 'picker__month fg-c1',
			year: 'picker__year fg-c1',

			highlighted: 'picker__day--highlighted bg-c2-opacity border-c1 border-top-c2--before',
			now: 'picker__day--today bg-c2-opacity',
			infocus: 'picker__day--infocus fg-c1 bg-c2_hover',
			outfocus: 'picker__day--outfocus fg-c2 bg-c2_hover',

			// Today, clear, & close buttons
			buttonClear: 'picker__button--clear fg-c1 border-top-c1--before',
			buttonClose: 'picker__button--close fg-c1',
			buttonToday: 'picker__button--today fg-c1 border-top-c2--before'
		},
		onStart: function(context) {
			
		},
		onOpen: function(context) {
			$('.flex-control-nav').hide();
			$('.flex-direction-nav').hide();
			console.log('datePicker checkin onOpen fired.');
			$('.picker__weekday-display').addClass('row-calendar-checkin');
			$('.picker__weekday-display').removeClass('row-calendar-checkout');
			// Set a variable with the value of the checkout datepicker.
			$checkoutDate = $('.booking-widget-calendar:eq(1) input').pickadate('picker').get();
			// console.log('checkoutDate=' + $checkoutDate );
			// If the checkout date is not set, make sure to clear the max value of the checkin picker.
			if ( $checkoutDate == '' ) {
				// console.log('From checkin, checkout date is not set.');
				$('.booking-widget-calendar:eq(0) input').pickadate('picker').set('max',false);
			}
		},
		onSet: function(context) {
			$checkoutDate = $('.booking-widget-calendar:eq(1) input').pickadate('picker').get();
			$checkinDate = $('.booking-widget-calendar:eq(0) input').pickadate('picker').get();
			console.log('Checkin onSet fired, checkoutDate=' + $checkoutDate);
			if (context.select) {
				// User picked a date.
				// console.log('Checkin onSet fired, context.select=true.');
				// Show the date they picked in the input field.
				$('.booking-widget-calendar:eq(0) input').css('opacity', '1');
				// Hide the "checkin" label now that a date is set.
				$('.row-reservation-bar-datepicker.checkin label').css('opacity', '0');
				// Set the min date on the checkout calendar since they can't checkout before they checkin.
				$picker_checkout.pickadate('picker').set('min',$checkinDate);
				// Close the checkin calendar now that they've selected a date.
				$picker_checkin.pickadate('picker').close();
				// And automatically open the checkout calendar for a better UX.
				$picker_checkout.pickadate('picker').open();
			} else {
				// The user did not pick a date, they clicked outside the box or on the close button.
				// console.log('Checkin onSet fired, context.select=false.');
				// Make sure the calendar label is still set to checkin.
				$('.picker__weekday-display').addClass('row-calendar-checkin');
				$('.picker__weekday-display').removeClass('row-calendar-checkout');
				if ( $checkinDate == '') {
					// Show the date they picked in the input field.
					$('.booking-widget-calendar:eq(0) input').css('opacity', '0');
					// Hide the "checkin" label now that a date is set.
					$('.row-reservation-bar-datepicker.checkin label').css('opacity', '1');
				}
			}
		},
		onClose: function(context) {
			$('.header-slider .flexslider .flex-control-nav').show();
			$('.header-slider .flexslider .flex-direction-nav').show();
		}
	});

	// Add a dash after the first calendar widget field
	$('.booking-widget-calendar:eq(0)').addClass('booking-widget-calendar-first');

	// Initialize the category dropdown
	$('.booking-widget-select select').selectric({
		responsive: true,
		inheritOriginalWidth: true,
		disableOnMobile: false,
		nativeOnMobile: false,
	});

}); /* end of as page load scripts */